import { register } from '@netcentric/component-loader';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import config from './batcom-flyout.config';
import { isOnMobile } from '../../../commons/utils';
import 'wicg-inert';

class Flyout {
  constructor(el) {
    this.el = el;
    this.selectors = config.selectors;
    this.classes = config.classes;
    this.previousActiveElement = null;
    this.isPreviewMode = this.el.dataset.ispreviewmode === 'true';
    this.isAuthor = this.el.dataset.isauthor === 'true';
    this.init();
  }

  init() {
    this.setRefs();
    this.setEventListeners();
  }

  setRefs() {
    this.flyoutAnchors = document.querySelectorAll(this.selectors.flyoutAnchor);
    this.flyoutDialog = this.el.querySelector(this.selectors.flyoutDialog);
    this.flyoutWindow = this.el.querySelector(this.selectors.flyoutWindow);
    this.flyoutBody = this.el.querySelector(this.selectors.flyoutBody);
    this.flyoutCloseButton = this.el.querySelector(this.selectors.flyoutCloseButton);
    this.flyoutCloseIcon = this.el.querySelector(this.selectors.flyoutCloseIcon);
    this.flyoutButtons = this.el.querySelector(this.selectors.flyoutButtons);
    this.flyoutMask = this.el.querySelector(this.selectors.flyoutMask);
  }

  setEventListeners() {
    this.flyoutAnchors.forEach(flyoutAnchor => {
      flyoutAnchor.addEventListener('click', e => {
        if (!this.flyoutDialog.classList.contains(this.classes.opened)) {
          e.preventDefault();
          this.previousActiveElement = document.activeElement;
          this.handleFlyoutAnchorClick(flyoutAnchor);
        }
      });
    });
  }

  handleFlyoutAnchorClick(flyoutAnchor) {
    const flyoutAnchorHref = flyoutAnchor.href;
    const addWcmmode = this.isAuthor && this.isPreviewMode;
    const url = addWcmmode ? `${flyoutAnchorHref}?wcmmode=disabled` : flyoutAnchorHref;
    const flyoutConfig = flyoutAnchor.dataset.flyoutConfig || '{}';

    (async () => {
      const response = await fetch(url);
      this.flyoutBody.innerHTML = await response.text();;
      const flyoutFooterButtonContainer = this.flyoutBody.querySelector(this.selectors.flyoutFooterButtonContainer);

      if (flyoutFooterButtonContainer) {
        this.addFlyoutFooterButton(flyoutFooterButtonContainer);
      }

      this.openFlyout(flyoutConfig);
    })();
  }

  addFlyoutFooterButton(flyoutFooterButtonContainer) {
    const flyoutFooterButtonContainerClone = flyoutFooterButtonContainer.cloneNode(true);
    flyoutFooterButtonContainer.style.display = 'none';
    this.flyoutButtons.append(flyoutFooterButtonContainerClone);
    this.flyoutBody.classList.add(this.classes.hasButtons);
    this.flyoutFooterButton = this.flyoutButtons.querySelector(this.selectors.flyoutFooterButton);
  }

  setFlyoutStyles(flyoutConfig) {
    // Update the flyout styles which can be configured in the flyout template in page propeprties, MODAL tab.
    const {
      bg = '#000',
      desktopWidth = '768px',
      mobilePadding = '20px',
      closeIcon,
    } = JSON.parse(flyoutConfig) || {};

    this.flyoutMask.style.backgroundColor = bg;

    if (!isOnMobile()) {
       this.flyoutWindow.style.width = desktopWidth;
    }

    if (isOnMobile()) {
      this.flyoutWindow.style.paddingLeft = mobilePadding;
      this.flyoutWindow.style.paddingRight = mobilePadding;
    }

    if (closeIcon) {
      this.flyoutCloseIcon.style.maskImage = `url(${closeIcon})`;
    }

  }

  openFlyout(flyoutConfig) {
    this.closeFlyout = this.closeFlyout.bind(this);
    this.checkCloseFlyout = this.checkCloseFlyout.bind(this);
    this.setInertValue(true);
    this.flyoutBody.scrollTop = 0;

    this.setFlyoutStyles(flyoutConfig);

    this.flyoutDialog.classList.add(this.classes.opened);
    this.flyoutDialog.setAttribute('aria-hidden', 'false');

    if (isOnMobile()) {
      this.flyoutWindow.classList.toggle(this.classes.slideIn);
    }

    disableBodyScroll(this.flyoutBody);
    document.addEventListener('keydown', this.checkCloseFlyout);
    this.flyoutCloseButton.addEventListener('click', this.closeFlyout);
    this.flyoutMask.addEventListener('click', this.closeFlyout);

    if (this.flyoutFooterButton) {
      this.flyoutFooterButton.addEventListener('click', this.closeFlyout);
    }

    this.flyoutCloseButton.focus();
  }

  closeFlyout() {
    this.setInertValue(false);
    document.removeEventListener('keydown', this.checkCloseFlyout);
    this.flyoutCloseButton.removeEventListener('click', this.closeFlyout);
    this.flyoutMask.removeEventListener('click', this.closeFlyout);

    if (this.flyoutFooterButton) {
      this.flyoutFooterButton.removeEventListener('click', this.closeFlyout);
    }

    if (isOnMobile()) {
      this.flyoutWindow.classList.toggle(this.classes.slideIn);
    }

    this.flyoutDialog.classList.remove(this.classes.opened);
    this.flyoutDialog.setAttribute('aria-hidden', 'true');
    this.flyoutBody.classList.remove(this.classes.hasButtons);
    this.flyoutBody.innerHTML = '';
    this.flyoutButtons.innerHTML = '';
    enableBodyScroll(this.flyoutBody);
    this.previousActiveElement.focus();
  }

  checkCloseFlyout(e) {
    if (e.keyCode === 27) {
      // ESC
      this.closeFlyout();
    }
  }

  /*
    Used to make the HTML elements outside of the flyout inert when set to true for a11y purposes
    Only elements that are part of the flyout will be focusable when it's opened
  */
  setInertValue(value) {
    Array.from(document.body.children).forEach(child => {
      if (child !== this.el) {
        child.inert = value;
      }
    });
  }
}

register({ Flyout });
